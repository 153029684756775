import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import HomeIcon from '@mui/icons-material/Home';
import AddToPhotosIcon from '@mui/icons-material/AddToPhotos';
import PictureInPictureIcon from '@mui/icons-material/PictureInPicture';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import dayjs from 'dayjs';
import { differenceInDays, formatDistanceToNow } from 'date-fns';
import { TableCell, Typography } from '@mui/material';
import { Loader } from '../components';
import { GSBStatus, GSBStatusId } from '../helpers/types';

export const PER_PAGE_RECORDS = 50;

export const menuItems = [
  {
    id: 1,
    menuName: 'Home',
    icon: <HomeIcon />,
    to: '/dashboard',
  },
  {
    id: 2,
    menuName: 'Takedown',
    icon: <TakeoutDiningIcon />,
    to: '/takedown',
  },
  {
    id: 3,
    menuName: 'Companies',
    icon: <AddToPhotosIcon />,
    to: '/companies',
  },
  {
    id: 4,
    menuName: 'Network Disruption',
    icon: <CircleNotificationsIcon />,
    to: '/network-disruption',
  },
  {
    id: 5,
    menuName: 'False Positive',
    icon: <PictureInPictureIcon />,
    to: '/false-positive',
  },
  {
    id: 6,
    menuName: 'GSB History',
    icon: <PictureInPictureIcon />,
    to: '/gsb-history',
  },
];

export const threatsStatus = [
  'confirmed',
  'discarded',
  'trademark',
  'owned',
  'detected',
];

export const statusList: any = [
  { Id: 1, Name: 'detected' },
  { Id: 2, Name: 'confirmed' },
  { Id: 3, Name: 'discarded' },
  { Id: 6, Name: 'trademark' },
  { Id: 7, Name: 'owned' },
  { Id: 5, Name: 'pre-confirmed' },
  { Id: 8, Name: 'pre-confirmed-by-matrix' },
  { Id: 9, Name: 'monitored' },
  { Id: 10, Name: 'pre-confirmed-by-brp' },
];

export const options = [
  { value: 'detected', label: 'Detected' },
  { value: 'confirmed', label: 'Confirmed' },
  { value: 'discarded', label: 'Discarded' },
  { value: 'trademark', label: 'Trademark' },
  { value: 'owned', label: 'Owned' },
  { value: 'pre-confirmed', label: 'Pre-confirmed' },
  { value: 'pre-confirmed-by-matrix', label: 'Pre-confirmed-by-matrix' },
  { value: 'monitored', label: 'monitored' },
  { value: 'pre-confirmed-by-brp', label: 'pre-confirmed-by-brp' },
];

export const riskData = ['high', 'medium', 'low'];

export const getStatusColor = (status?: any) => {
  if (status === 'confirmed') {
    return '#50CD89';
  } else if (status === 'detected') {
    return '#00B2FF';
  } else if (status === 'discarded') {
    return '#131628';
  } else if (status === 'trademark') {
    return '#7239EA';
  } else if (status === 'owned') {
    return '#25badb';
  } else if (status === 'pre-confirmed') {
    return '#1976d2';
  } else if (status === 'pre-confirmed-by-matrix') {
    return '#01B2FF';
  } else if (status === 'monitored') {
    return '#1976d2';
  } else if (status === 'pre-confirmed-by-brp') {
    return '#00B2FF';
  }
  return '#50CD89';
};

export const DNSRecordsHeader = ['Record Type', 'Address', 'Domain Name'];

export const SSLCertificateHeader = [
  'Domain',
  'Issuer',
  'Registered On',
  'Not After',
];

export const TakedownListHeader = [
  'User',
  'Created On',
  'Finished On',
  'Status',
  'Messsage',
];

export const GSBHistoryHeader = [
  'Id',
  'Domain',
  'URL',
  'Platform',
  'Region',
  'Labels',
  'Comments',
  'Abuse Type',
  'Created On',
  'Operation Id',
  'Status',
];

export const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const getLabelsAndIds = (
  filterValues: any[],
  property: string | number,
) => {
  return filterValues?.reduce((accumulator, currentItem) => {
    accumulator?.push(currentItem[property]);
    return accumulator;
  }, []);
};

export const defaultStatusLabel = [{ label: 'confirmed' }];

export const defaultStartDate = dayjs()
  .subtract(1, 'month')
  .startOf('day')
  .format('YYYY-MM-DD HH:mm:ss');

export const defaultEndDate = dayjs()
  .endOf('day')
  .format('YYYY-MM-DD HH:mm:ss');

export const defaultStartDateTakedown = dayjs()
  .subtract(4, 'day')
  .startOf('day')
  .format('YYYY-MM-DD HH:mm:ss');

export const defaultEndDateTakedown = dayjs()
  .endOf('day')
  .format('YYYY-MM-DD HH:mm:ss');

export const companyStatusList = [
  { value: 'active', label: 'active' },
  { value: 'deleted', label: 'deleted' },
  { value: 'deactivated', label: 'deactivated' },
  { value: 'onboardingPending', label: 'onboardingPending' },
  { value: 'onboarded', label: 'onboarded' },
  { value: 'error', label: 'error' },
  { value: 'owned', label: 'owned' },
];

export const formatedDate = (date: string | number | Date) => {
  return new Date(date).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
};

export const validatePassword = (password: any) => {
  const minLength = 12;
  const hasUpperCase = /[A-Z]/.test(password);
  const hasLowerCase = /[a-z]/.test(password);
  const hasDigit = /\d/.test(password);

  if (
    password.length < minLength ||
    !hasUpperCase ||
    !hasLowerCase ||
    !hasDigit
  ) {
    return 'Password too easy. The password must contain at least 12 characters with, at least, 1 digit, 1 uppercase and 1 lowercase letter.';
  }
  return '';
};

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return 'Invalid email format.';
  }
  return '';
};

export const formatDateActivity = (date: string) => {
  const dateObj = new Date(date);
  const daysDifference = differenceInDays(new Date(), dateObj);
  if (daysDifference > 31) {
    return `${Math.floor(daysDifference / 30)} month(s) ago`;
  }
  return `${formatDistanceToNow(dateObj)} ago`;
};

export const NoDataMessage = ({ message }: { message: string }) => (
  <TableCell colSpan={12} align="center" sx={{ border: 0, py: 6 }}>
    <Typography variant="subtitle1">{message}</Typography>
  </TableCell>
);

export const LoaderWrapper = ({ size }: { size: number }) => (
  <TableCell colSpan={12} align="center" sx={{ border: 0, py: 6 }}>
    <Loader size={size} />
  </TableCell>
);

export const exportToCSV = (data: any, filename = 'export.csv') => {
  const csvContent = [
    [
      'URL',
      'Target',
      'Description',
      'User ID',
      'Requested',
      'Finished',
      'Finish Description',
    ],
    ...data.map((item: any) => [
      item.url,
      item.target,
      item.description,
      item.user_id,
      new Date(item.created).toLocaleDateString(),
      new Date(item.finished).toLocaleDateString(),
      item.finished_description,
    ]),
  ]
    .map(e => e.join(','))
    .join('\n');

  const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
  const link = document.createElement('a');
  const url = URL.createObjectURL(blob);
  link.setAttribute('href', url);
  link.setAttribute('download', filename);
  link.style.visibility = 'hidden';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const activeDefense = [
  {
    domain: 'facebook.safe-transfer-link.com',
    created_by: 'Unknown',
    created_date: '04 Jul 2024, 11:55 AM',
  },
  {
    domain: 'nanliteglobal.ru',
    created_by: 'Nelson Alikou',
    created_date: '04 Jul 2024, 11:44 AM',
  },
  {
    domain: 'test.metamenon.com',
    created_by: 'Nakul K',
    created_date: '14 Jun 2024, 01:01 PM',
  },
  {
    domain: 'meta-advertising-business.com',
    created_by: 'Nakul K',
    created_date: '12 Jan 2024, 07:43 AM',
  },
  {
    domain: 'nanliteglobal.ru',
    created_by: 'Nelson Alikou',
    created_date: '04 Jul 2024, 11:44 AM',
  },
  {
    domain: 'test.metamenon.com',
    created_by: 'Nakul K',
    created_date: '14 Jun 2024, 01:01 PM',
  },
  {
    domain: 'meta-advertising-business.com',
    created_by: 'Nakul K',
    created_date: '12 Jan 2024, 07:43 AM',
  },
];

export const falsePositive = [
  {
    domain: 'betpublicamp27.com',
    created_by: 'Abu Qureshi',
    created_date: '	26 Aug 2024, 02:10 PM',
  },
  {
    domain: 'nanliteglobal.ru',
    created_by: 'Nelson Alikou',
    created_date: '04 Jul 2024, 11:44 AM',
  },
  {
    domain: 'dog4move.online',
    created_by: 'Keerthi Chevuturi',
    created_date: '26 Aug 2024, 02:10 PM',
  },
  {
    domain: 'the-coven.fr',
    created_by: 'Keerthi Chevuturi',
    created_date: '12 Jan 2024, 07:43 AM',
  },
  {
    domain: 'nanliteglobal.ru',
    created_by: 'Nelson Alikou',
    created_date: '04 Jul 2024, 11:44 AM',
  },
  {
    domain: 'test.metamenon.com',
    created_by: 'Keerthi Chevuturi',
    created_date: '14 Jun 2024, 01:01 PM',
  },
  {
    domain: 'empowermindsetmarket.store',
    created_by: 'Nakul K',
    created_date: '26 Aug 2024, 02:10 PM',
  },
];

export const disableAfterToday = (date: Date) => {
  const today = new Date();
  today.setHours(23, 59, 59, 999);
  return date.getTime() > today.getTime();
};

export const SourcesData = [
  { label: 'Client Panel', value: 'client_panel' },
  { label: 'Daily Scan', value: 'daily_scan' },
  { label: 'Log Detection', value: 'log_detection' },
  { label: 'Matrix', value: 'matrix' },
  { label: 'Onboarding Scan', value: 'onboarding_scan' },
  { label: 'Wheel', value: 'wheel' },
];

export const isValidUrl = (str: string): boolean => {
  const regex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  return regex.test(str);
};

export const getFormattedDescription = (
  description: string | undefined,
): string => {
  return description ? `${description.slice(0, 30)}...` : description || '-';
};

export const findGSBStatus = (
  id: number,
  statusData: GSBStatusId['data'],
): string =>
  statusData?.find((status: GSBStatus) => status?.id === id)?.name || 'Unknown';
export const defaultServiceFilter = [
  {
    id: 7,
    label: 'Precrime Brand - POC - High Priority',
  },
  {
    id: 5,
    label: 'Precrime Brand - Active Paying Customer',
  },
];

export const defaultStatusFilter = [
  { id: 1, label: 'detected' },
  { id: 8, label: 'pre-confirmed-by-matrix' },
  { id: 10, label: 'pre-confirmed-by-brp' },
];

export const getDateOrDefault = (dateArray: string, defaultDate: string) => {
  return dateArray.length === 0 ? defaultDate : dateArray;
};

export const TakeDownTypes = {
  identifiedThreatDomain: 'identified_threat_domain',
  externalThreatDomain: 'external_threat_domain',
  socialMedia: 'social_media',
};

export const TakeDownTypeList = [
  TakeDownTypes.identifiedThreatDomain,
  TakeDownTypes.externalThreatDomain,
  TakeDownTypes.socialMedia,
];

export const formatLabel = (str: string) => {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const convertToUnixTimestamp = (date: Date): number => {
  return Math.floor(date.getTime() / 1000);
};

const dateDiffInHours = (date: string) => {
  const date1 = dayjs(date);
  const date2 = dayjs();
  return date2.diff(date1) / 3600000;
};

export const isRecentNetworkRecords = (type: string, date: string) => {
  const timeDiff = dateDiffInHours(date);
  return timeDiff < 24;
};

export const getExternalUrl = (type: string, name: string) => {
  switch (type) {
    case 'urlscan':
      return `https://urlscan.io/domain/${name}`;
    case 'archive':
      return `https://web.archive.org/web/20220000000000*/${name}`;
    case 'transparencyreport':
      return `https://transparencyreport.google.com/safe-browsing/search?url=${name}`;
    case 'crt':
      return `https://crt.sh/?q=${name}`;
    case 'virustotal':
      return `https://www.virustotal.com/gui/domain/${name}`;
    case 'threatintelligenceplatform':
      return `https://threatintelligenceplatform.com/report/${name}/vnrzbY2T1F`;
    case 'dnschecker':
      return `https://dnschecker.org/#A/${name}`;
    default:
      return '';
  }
};

export const defaultSourcesFilter = SourcesData.filter(
  source => source.label === 'Daily Scan',
);
