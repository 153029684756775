import { useState } from 'react';
import {
  createNetworkByType,
  getNetworkDataByType,
  getNetworkTaskById,
} from '../store/singleThreat';
import { isRecentNetworkRecords } from '.';
import { UseThreatResult } from '../helpers/types';

const useCustomThreatHook = (): UseThreatResult => {
  const [threatData, setThreatData] = useState<any>({});

  const createNetworkDataByType = async (
    type: 'whois' | 'certificate',
    items: any,
  ) => {
    const createdWhois = await createNetworkByType({
      asset_id: items?.threat_id,
      type,
    });

    setTimeout(async () => {
      const resTaskById = await getNetworkTaskById(createdWhois?.data.task_id);
      setThreatData((prevData: any) => ({
        ...prevData,
        [items?.threat_id]: {
          ...prevData[items?.threat_id],
          [type]: resTaskById?.data,
          [`${type}Loading`]: false,
        },
      }));
    }, 3000);
  };

  const fetchNetworkRecords = async (
    type: 'whois' | 'certificate',
    items: any,
  ) => {
    const { threat_id: threatId } = items;
    try {
      if (!threatData[threatId] || !threatData[threatId][type]) {
        setThreatData((prevData: any) => ({
          ...prevData,
          [threatId]: {
            ...prevData[threatId],
            [`${type}Loading`]: true,
          },
        }));

        const result = await getNetworkDataByType(threatId, type);

        const isDataExists =
          result?.data?.items.length > 0 &&
          isRecentNetworkRecords(type, result?.data?.items?.[0].created_at);

        if (isDataExists) {
          setThreatData((prevData: any) => ({
            ...prevData,
            [threatId]: {
              ...prevData[threatId],
              [type]: result.data?.items?.[0].data,
              [`${type}Loading`]: false,
            },
          }));
        } else {
          createNetworkDataByType(type, items);
        }
      }
    } catch (error) {
      setThreatData((prevData: any) => ({
        ...prevData,
        [threatId]: {
          ...prevData[threatId],
          [`${type}Loading`]: false,
        },
      }));
      console.error(error);
    }
  };

  return {
    threatData,
    fetchNetworkRecords,
  };
};

export default useCustomThreatHook;
