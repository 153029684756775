import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Pagination, Typography } from '@mui/material';
import { Button } from '../../components';
import { FilterWrapper } from '../CompaniesList/Style/CompaniesList.style';
import { PaginationStyle } from '../ThreadsDetails/Style/SingleThreat.style';
import ActiveDefenseTable from './ActiveDefenseTable';
import { getActiveDefenceAction } from '../../store/activeDefence/action';
import DatePickers from '../../components/SingleDatePicker/SingleDatePicker';
import { convertToUnixTimestamp, disableAfterToday } from '../../utils';
import LoadDomainModal from '../../components/LoadDomainsModal/LoadDomainModal';
import { createActiveDefenceDomainsApi } from '../../store/activeDefence';
import { showToast } from '../../store/toaster/action';

const ActiveDefense = () => {
  const [openActiveDefenseModal, setOpenActiveDefenseModal] =
    useState<boolean>(false);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { data, loading, error } = useSelector(
    (state: any) => state.activeDefence.activeDefence,
  );

  const handleOpenActiveLoadDomain = () => {
    setOpenActiveDefenseModal(true);
  };

  const handleCloseActiveDefenseModal = () => {
    setOpenActiveDefenseModal(false);
  };

  const handleSubmitActiveDefenseDomains = async (domains: string) => {
    const ActiveDomainsArray = domains
      .split(',')
      .map(domain => domain.trim())
      .filter(domain => domain);
    const payload = ActiveDomainsArray;
    setIsLoading(true);
    try {
      const res = await createActiveDefenceDomainsApi(payload);
      dispatch(showToast('Domains added successfully', 'success'));
      handleCloseActiveDefenseModal();
      setIsLoading(false);
    } catch (err) {
      dispatch(showToast('Failed to add domains ', 'error'));
      setIsLoading(false);
    }
  };

  const handleStartDateChange = (date: Date | null) => {
    if (date) {
      setSelectedStartDate(date);
      const unixTimestamp = convertToUnixTimestamp(date);
      dispatch(getActiveDefenceAction({ startDate: unixTimestamp }));
    }
  };

  useEffect(() => {
    handleStartDateChange(new Date());
  }, [dispatch]);

  return (
    <>
      <Box sx={{ padding: '24px' }}>
        <Typography variant="h6" sx={{ fontWeight: 600, color: 'black' }}>
          Active defence
        </Typography>
        <FilterWrapper>
          <Box sx={{ display: 'flex', gap: '12px' }}>
            <Box sx={{ width: '240px' }}>
              <DatePickers
                onChange={handleStartDateChange}
                value={selectedStartDate}
                disableAfterToday={disableAfterToday}
              />
            </Box>
          </Box>
          <Box>
            <Button
              variant="contained"
              sx={{ background: '#0fd6c2' }}
              onClick={handleOpenActiveLoadDomain}>
              + Load Domain
            </Button>
          </Box>
        </FilterWrapper>
        <ActiveDefenseTable data={data} loading={loading} />
      </Box>
      <LoadDomainModal
        title="Load Network Disruption"
        handleClose={handleCloseActiveDefenseModal}
        handleSubmit={handleSubmitActiveDefenseDomains}
        open={openActiveDefenseModal}
        isLoading={isLoading}
      />
    </>
  );
};

export default ActiveDefense;
